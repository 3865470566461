<template>
  <el-collapse-item :title="faqItem.title">
    <div v-if="faqItem.content" class="faq-item-content" v-html="faqItem.content"></div>
    <el-collapse accordion v-if="faqItem.children">
      <FaqItem v-for="(item, idx) in faqItem.children" :key="idx" :faq-item="item"></FaqItem>
    </el-collapse>
  </el-collapse-item>
</template>

<script>
export default {
  name: 'FaqItem',
  props: {
    faqItem: {
      type: Object
    }
  }
}
</script>
