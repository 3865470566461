<template>
  <div class="app-container">
    <el-card>
      <h2 class="heading page-heading">{{ $t('page_faq.title') }}</h2>
      <el-collapse accordion>
        <FaqItem class="el-collapse-item--first-level" v-for="(item, idx) in schema" :key="idx" :faq-item="item" />
      </el-collapse>
    </el-card>
  </div>
</template>

<script>
import FaqItem from './components/faq-item/FaqItem'

export default {
  name: 'FaqPage',
  components: {
    FaqItem
  },
  computed: {
    schema() {
      return this.$t('page_faq.schema')
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";
.el-collapse-item {
  user-select: none;
}
.el-collapse-item--first-level {
  background-color: #ebebeb;
  margin-bottom: 5px;
  & .el-collapse-item__header {
    background-color: transparent;
    padding-left: 0.5em;
  }
}
.el-collapse-item__header {
  font-size: 1em;
  @include tablet {
    line-height: 1.5em;
    padding: 0.5em 0;
  }
}
.el-collapse-item__content {
  padding: 0 1em 1em;
}
</style>
